<template>
  <BaseDialog
    class="dialog-warpper"
    :dialogVisible="dialogVisible"
    title="侵权设置"
    width="750px"
    @closeHandle="handleClose"
  >
    <span class="mr10">是否标记为侵权公共产品：</span>
    <el-radio v-model="radio" :label="0">否</el-radio>
    <el-radio v-model="radio" :label="1">是</el-radio>
    <template #footer>
      <loadingBtn type="primary" @click="onConfirm"> 确定 </loadingBtn>
      <el-button @click="handleClose"> 取消 </el-button>
    </template>
  </BaseDialog>
</template>

<script>
import { sign } from '@/api/finishedProductManageApi'

export default {
  props: {
    dialogVisible: Boolean,
    selectedData: Array,
    sup_this: Object,
    type: String
  },
  data() {
    return {
      radio: 0
    }
  },
  created() {
    if (this.type == 'single') this.radio = this.selectedData[0].isTort
    else if (this.selectedData.length == 1) this.radio = this.selectedData[0].isTort
  },
  computed: {
    getProductList({ selectedData }) {
      return selectedData.map(({ chineseName }) => chineseName).join('、')
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
    async onConfirm() {
      const res = await awaitResolve(
        sign({
          collectionIdList: this.selectedData.map(({ id }) => id),
          isTort: this.radio
        })
      )
      if (!res) return
      this.handleClose()
      this.sup_this.init()
      this.$message.success('操作成功')
    }
  }
}
</script>

<style lang="scss" scoped></style>
