<template>
  <div>
    <BaseDialog class="dialog-warpper" :dialogVisible="visible" :title="title" width="60%" @closeHandle="handleClose">
      <template>
        <BatchOperation v-if="isBatch">
          <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate">全选</el-checkbox>
          <el-pagination
            class="defaultClass"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.pageIndex"
            :hide-on-single-page="true"
            :page-sizes="[10, 20, 50, 100, 200]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="selectionDataCopy.length"
          >
          </el-pagination>
        </BatchOperation>
        <el-table
          ref="tableRef"
          border
          height="auto"
          max-height="400px"
          style="width: 100%"
          :cell-class-name="cellClassName"
          :data="finalData"
          @select="selectHandler"
          @select-all="selectAllHandler"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" label="公共产品名称"> </el-table-column>
          <el-table-column label="SKU" width="400">
            <template slot-scope="{ row }">
              <div :key="index" v-for="(item, index) in getSku(row)" class="row-line">
                <!-- <el-checkbox v-model="item.checked">{{ item.sku }}</el-checkbox> -->
                {{ item.sku }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="款式">
            <template slot-scope="{ row }">
              <div v-for="(item, index) in getStyleName(row)" :key="index" class="row-line">
                <el-tag type="primary">
                  {{ item }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="尺码">
            <template slot-scope="{ row }">
              <div :key="index" v-for="(item, index) in getSize(row) || []" class="row-line">
                <el-tag type="primary">
                  {{ item.sizeName }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="isBatch"
          class="defaultClass"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageIndex"
          :hide-on-single-page="true"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="selectionDataCopy.length"
        >
        </el-pagination>
      </template>
      <template #footer>
        <div :class="{ ac: true, 'flex-between': isPutOn }">
          <div class="info" v-if="isPutOn">公共产品上架后，业务可用公共产品的SKU直接下单</div>
          <div class="tr">
            <loadingBtn type="primary" @click="onConfirm" :isDisabled="!!!selectionDataCopy.length"> 确定 </loadingBtn>
            <loadingBtn @click="onCancel"> 取消 </loadingBtn>
          </div>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { deepClone } from '@/components/avue/utils/util'
import { putOnProducts, putOffProducts } from '@/api/finishedProductManageApi'
import { flat } from '@/utils'
import { IS_PUBLIC_OFF_SHELF, IS_PUBLIC_ON_SHELF } from '@/utils/constant'

let options = {
  putOn: {
    title: '上架',
    isPublicOnShelf: IS_PUBLIC_OFF_SHELF
  },
  putOff: {
    title: '下架',
    isPublicOnShelf: IS_PUBLIC_ON_SHELF
  },
  batchPutOn: {
    title: '批量上架',
    isPublicOnShelf: IS_PUBLIC_OFF_SHELF
  },
  batchPutOff: {
    title: '批量下架',
    isPublicOnShelf: IS_PUBLIC_ON_SHELF
  }
}

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectionData: {
      type: Array,
      default: () => []
    },
    sup_this: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'putOn'
    }
  },

  data() {
    return {
      selectionDataCopy: [],
      page: {
        pageIndex: 1,
        pageSize: 10
      }
    }
  },

  watch: {
    finalData: {
      handler(newVal) {
        newVal.map((item) => {
          const { customProductList } = item
          customProductList.map((cItem) => {
            const { customSpecificProductList } = cItem
            const checked = customSpecificProductList.every((pItem) => pItem.checked)
            cItem.checked = checked
          })
          const itemChecked = customProductList.every(({ checked }) => checked)
          item.checked = itemChecked
          this.$nextTick(() => {
            const tableRef = this.$refs.tableRef
            if (tableRef) {
              tableRef.toggleRowSelection(item, item.checked)
            }
          })
        })
      },
      deep: true
    }
  },

  computed: {
    checkAll: {
      get({ selectionDataCopy }) {
        return selectionDataCopy.every(({ checked }) => checked)
      },

      set(bool) {
        this.toggleChecked(this.selectionDataCopy, bool)
      }
    },

    isIndeterminate({ selectionDataCopy }) {
      const checked = selectionDataCopy.every(({ checked }) => checked)
      const someoneChecked = selectionDataCopy.some(({ checked }) => checked)
      if (someoneChecked && !checked) {
        return true
      }
      return false
    },

    getSize() {
      return ({ customProductList }) => {
        const allSizeList = flat(
          customProductList?.map(({ customSpecificProductList }) => flat(customSpecificProductList))
        )
        const condition = this.isPublicOnShelf
        return allSizeList
          .filter(({ isPublicOnShelf }) => {
            return isPublicOnShelf == condition
          })
          .filter(({ sizeName }) => sizeName)
      }
    },

    finalData({ selectionDataCopy: data, page: { pageIndex, pageSize } }) {
      return (data || []).slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
    },

    getSku() {
      return ({ customProductList }) => {
        const allSkuList = flat(customProductList?.map(({ customSpecificProductList }) => customSpecificProductList))
        const condition = this.isPublicOnShelf
        return allSkuList.filter(({ isPublicOnShelf }) => {
          return isPublicOnShelf == condition
        })
      }
    },

    option() {
      return options[this.type]
    },
    title() {
      return this.option.title
    },
    isPublicOnShelf() {
      return this.option.isPublicOnShelf
    },
    isBatch() {
      return ['batchPutOn', 'batchPutOff'].includes(this.type)
    },
    isPutOn() {
      return ['batchPutOn', 'putOn'].includes(this.type)
    }
  },

  created() {
    const selectionDataCopy = deepClone(this.selectionData)
    this.selectionDataCopy = this.toggleChecked(selectionDataCopy, true)
  },

  methods: {
    getStyleName(row) {
      const styleArr = []
      const skuList = this.getSku(row).map(({ sku }) => sku)
      row.customProductList.map((item) => {
        item.customSpecificProductList.map(({ sku }) => {
          if (skuList.includes(sku)) {
            styleArr.push(item.styleName)
          }
        })
      })
      return styleArr
    },
    selectAllHandler(selection) {
      this.toggleChecked(this.finalData, !!selection.length)
    },

    selectHandler(selection, row) {
      this.toggleChecked([row], !row.checked)
    },

    toggleChecked(data, bool = false) {
      if (!Array.isArray(data)) {
        data = [data]
      }

      data.map((item, index) => {
        if (!item.customProductList) {
          item.customProductList = []
        }
        let { customProductList } = item
        customProductList.map((cItem) => {
          if (!cItem.customSpecificProductList) {
            cItem.customSpecificProductList = []
          }
          const { customSpecificProductList } = cItem
          customSpecificProductList.map((pItem) => {
            pItem.checked = bool
          })
          const checked = customSpecificProductList.every((pItem) => pItem.checked)
          cItem.checked = checked
        })
        const itemChecked = customProductList.every(({ checked }) => checked)
        item.checked = itemChecked
      })
      return data
    },

    handleClose() {
      this.$emit('update:visible', false)
    },

    handleSizeChange(val) {
      this.page.pageSize = val
    },

    handleCurrentChange(val) {
      this.page.pageIndex = val
    },

    cellClassName({ columnIndex }) {
      if ([2, 3, 4].includes(columnIndex)) {
        return 'clearTablePadding'
      }
    },
    onCancel() {
      this.$emit('update:selectionData', this.selectionDataCopy)
      this.$emit('update:visible', false)
    },
    async onConfirm() {
      const list = []
      const state = this.isPublicOnShelf
      this.selectionDataCopy?.map((item) => {
        const { customProductList } = item
        customProductList?.map((cItem) => {
          cItem.customSpecificProductList = cItem.customSpecificProductList?.filter(({ isPublicOnShelf }) => {
            return isPublicOnShelf == state
          })
        })
      })
      const hasCheckedSku = this.selectionDataCopy.some((row) => {
        return row.customProductList.some(({ customSpecificProductList }) =>
          customSpecificProductList.some(({ checked }) => checked)
        )
      })

      if (hasCheckedSku) {
        this.selectionDataCopy.map(async (row) => {
          const canPost = row.customProductList.some(({ customSpecificProductList }) =>
            customSpecificProductList.some(({ checked }) => checked)
          )
          if (!canPost) return
          const collectionId = row.id
          const selectSkuItem = flat(
            row.customProductList.map(({ customSpecificProductList }) =>
              customSpecificProductList.filter(({ checked }) => checked)
            )
          )
          let productIdList = Array.from(new Set(selectSkuItem.map(({ productId }) => productId)))
          const skuIdList = selectSkuItem.map(({ id }) => id)
          list.push({
            collectionId,
            productIdList,
            skuIdList
          })
        })
        try {
          const postParams = { list }
          const fun = this.isPutOn ? putOnProducts : putOffProducts
          const { code } = await fun(postParams)
          if (code == 0) {
            this.$emit('update:visible', false)
            this.sup_this.init()
            const message = this.title
            this.$message.success(`${message}成功`)
          }
        } catch (error) {}
      } else {
        const message = this.isPutOn ? '下架' : '上架'
        this.$message.warning(`请先选择需要${message}的SKU`)
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  .defaultClass.el-pagination {
    text-align: right;
    padding: 10px 0;
    padding-right: 15px;
  }
  .row-line {
    border-bottom: 1px solid #ebeef5;
    margin: 0 -10px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row-line:last-child {
    border-bottom: none;
  }
  .tr {
    text-align: right;
  }
  .ac {
    align-items: center;
  }
  .info {
    color: #bdb8b8;
  }
}
</style>
