import { render, staticRenderFns } from "./putOnOrPutOff.vue?vue&type=template&id=f1244178&scoped=true&"
import script from "./putOnOrPutOff.vue?vue&type=script&lang=js&"
export * from "./putOnOrPutOff.vue?vue&type=script&lang=js&"
import style0 from "./putOnOrPutOff.vue?vue&type=style&index=0&id=f1244178&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1244178",
  null
  
)

export default component.exports