<template>
  <div class="app-container">
    <!-- <select-merchant
      v-if="permission.selectMerchant"
      :sup_this="sup_this"
      :allMerchantList="allMerchantList"
    /> -->
    <myHeader v-if="permission.search" :query="query" @search="search" />
    <BatchOperation style="display: block; margin-bottom: -20px">
      <checkAllData
        class="mr10"
        ref="checkAllData"
        :tableData="data"
        :page="page"
        :size="size"
        :total="total"
        :isPageChange="isPageChange"
        :setIsPageChangeFalse="setIsPageChangeFalse"
        :getAllData="getAllData"
        :selectionData="selectedData"
        :getElTableInstance="getElTableInstance"
        @checkAllStatus="checkAllStatusChange"
      ></checkAllData>
      <loading-btn size="mini" v-if="permission.batchPutOn" @click="changeStatus('test', 'batchPutOn')"
        >批量上架</loading-btn
      >
      <loading-btn size="mini" v-if="permission.batchPutOff" @click="changeStatus('test', 'batchPutOff')"
        >批量下架</loading-btn
      >
      <el-button v-if="permission.batchModifyStyle" size="mini" @click="batchOperation('modify')"
        >批量修改风格</el-button
      >
      <el-button v-if="permission.batchSign" size="mini" @click="batchOperation('sign')">批量标记侵权</el-button>
    </BatchOperation>
    <div v-if="selectCount">
      <el-alert style="margin: 10px 0 20px" :title="`当前选择的产品数量为:${selectCount}个`" type="warning" show-icon>
      </el-alert>
    </div>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable
      @selection-change="handleSelectionChange"
      style="overflow: auto"
      :cell-class-name="cellClassName"
      ref="tableRef"
      :tableLoading="tableLoading"
      :selection="true"
      height="auto"
      :cols="cols"
      :infoData="data"
    >
      <el-table-column align="center" fixed="left" label="产品名称/款式" width="200px">
        <template slot-scope="{ row, $index }">
          <div class="text-cut m150">{{ row.name }}</div>
          <el-tag
            @click="handleStyleClicked(index, $index)"
            class="mr10 mt10 cp"
            type="primary"
            :class="{ active: row.$sizeIndex == index }"
            v-for="(item, index) in getStyleName(row)"
            :key="index"
          >
            {{ item.styleName }}
          </el-tag>
          <div></div>
        </template>
      </el-table-column>
      <template #knifeImageSlot="{ scoped: row }">
        <defaultImg :carouselList="knifeImageList(row)" :contentList="formatName(row)"></defaultImg>
        <el-divider v-if="row.$sizeList.length > 1" class="hidden"></el-divider>
      </template>
      <template #originalImageSlot="{ scoped: row }">
        <defaultImg
          :carouselList="rewriteMap([row], 'imageList.thumbnailPath')"
          :contentList="getPicTitle(row)"
        ></defaultImg>
      </template>
      <template #tortSlot="{ scoped: row }">
        {{ getTortStatus(row) }}
      </template>
      <template #showImageSlot="{ scoped: row }">
        <defaultImg :carouselList="showImageList(row)" :content="getTitle(row)"></defaultImg>
      </template>
      <template #styleSlot="{ scoped: row }">
        <el-tag class="mr10 mt10" type="primary" v-for="(item, index) in getStyle(row)" :key="index">
          {{ item }}
        </el-tag>
      </template>
      <template #customSkuSlot="{ scoped: row }">
        <div
          :key="index"
          v-for="(item, index) in getSku('customSku', row) || []"
          v-show="isSizeExpand(row, index)"
          class="row-line"
        >
          {{ item }}
        </div>
        <el-divider v-if="row.$sizeList.length > 1" class="hidden"></el-divider>
      </template>
      <template #skuSlot="{ scoped: row }">
        <div
          :key="index"
          v-for="(item, index) in getSku('sku', row) || []"
          v-show="isSizeExpand(row, index)"
          class="row-line"
        >
          {{ item }}
        </div>
        <el-divider v-if="row.$sizeList.length > 1" class="hidden"></el-divider>
      </template>
      <template #sizeSlot="{ row }">
        <div :key="index" v-for="(item, index) in row.$sizeList" v-show="isSizeExpand(row, index)" class="row-line">
          <el-tag type="primary">
            {{ item }}
          </el-tag>
        </div>
        <el-divider v-if="row.$sizeList.length > 1" @click="row.$isExpand = !row.$isExpand">{{
          row.$isExpand ? '收起尺码' : '展开更多'
        }}</el-divider>
      </template>
      <template #stateSlot="{ scoped: row }">
        <div class="row-line" v-for="(item, index) in getState(row)" :key="index" v-show="isSizeExpand(row, index)">
          {{ item }}
        </div>
        <el-divider v-if="row.$sizeList.length > 1" class="hidden"></el-divider>
      </template>
      <template #timeSlot="{ scoped }"> {{ parseTime(scoped.createTime) }}</template>
      <template #putTimeSlot="{ scoped }"> {{ parseTime(scoped.publicShelfTime) || '暂无' }}</template>

      <el-table-column align="center" fixed="right" label="操作" width="250px">
        <template slot-scope="{ row }">
          <color-text-btn
            v-if="permission.putOn && showStatus(row, 0)"
            class="menu-btn-item"
            @click="changeStatus(row, 'putOn')"
            >上架</color-text-btn
          >
          <color-text-btn
            v-if="permission.putOff && showStatus(row, 1)"
            class="menu-btn-item"
            @click="changeStatus(row, 'putOff')"
            >下架</color-text-btn
          >
          <color-text-btn v-if="permission.sign" class="menu-btn-item" @click="showDialog(row)"
            >标记侵权</color-text-btn
          >

          <div v-if="permission.generateKnifeImage">
            <color-text-btn
              v-if="!knifeImageList(row).filter(Boolean).length"
              class="menu-btn-item"
              @click="generate(row, 'knifeImage')"
              :loading="row.knifeLoading"
            >
              生成刀版图
            </color-text-btn>

            <color-text-btn
              v-else
              class="menu-btn-item"
              @click="generate(row, 'knifeImage')"
              :loading="row.knifeLoading"
            >
              更新刀版图
            </color-text-btn>
          </div>

          <div v-if="permission.generateShowImage">
            <color-text-btn
              v-if="!showImageList(row).filter(Boolean).length"
              class="menu-btn-item"
              @click="generate(row, 'displayImage')"
              :loading="row.showLoading"
            >
              生成效果图
            </color-text-btn>

            <color-text-btn
              v-else
              class="menu-btn-item"
              @click="generate(row, 'displayImage')"
              :loading="row.showLoading"
            >
              更新效果图
            </color-text-btn>
          </div>

          <!-- <statusBtn
            v-if="permission.delete && row.isPublicOnShelf !== IS_PUBLIC_ON_SHELF"
            :data="row"
            size="mini"
            disabledText="删除"
            type="publicProductBatchDelete"
            @success="init"
          ></statusBtn> -->
          <el-divider direction="vertical"></el-divider>
          <loading-btn v-if="permission.linkToDetail" style="font-size: 12px" type="text" @click="linkToDetail(row)"
            >详情</loading-btn
          >
        </template>
      </el-table-column>
    </CommonTable>
    <batchModifyStyle
      v-if="styleVisible"
      :allStyleList="allStyleList"
      :selectionList.sync="selectionList"
      :styleVisible.sync="styleVisible"
      :sup_this="sup_this"
    />
    <detailDialog ref="detailDialog" :data="detailData" />
    <putOnOrPutOff
      v-if="visible"
      :type="type"
      :selectionData.sync="selectionData"
      :visible.sync="visible"
      :sup_this="sup_this"
    />
    <batch-sign
      v-if="dialogVisible"
      :selectedData="selectionData"
      :type="type"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
    />
    <div class="pagination_wrapper">
      <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    </div>
  </div>
</template>

<script>
import selectMerchant from './module/selectMerchant.vue'
import batchModifyStyle from './module/batchModifyStyle'
import checkAllData from '@/views/components/checkAllData3'
import { checkPermission, parseTime, rewriteMap, getFileName } from '@/utils'
import myHeader from './module/header.vue'
import { getValueFromObj, flat } from '@/utils'
import { initDataMixin } from '@/mixins'
import { cols } from './module/cols'
import detailDialog from './module/detailDialog'
import { getMerchantList, getStyleList, getProductList } from '@/api/finishedProductManageApi'
import defaultImg from '@/views/components/defaultImg'
import putOnOrPutOff from './module/putOnOrPutOff.vue'
import batchSign from './module/batchSign.vue'
import { testAlgo, testAlgoShowImg } from '@/api/product/knifeProductApi'
import { deepClone } from '@/components/avue/utils/util'
import { IS_PUBLIC_ON_SHELF } from '@/utils/constant'

let permission = {
  selectMerchant: checkPermission('externaladmin:finishedProductManage:product:selectMerchant'),
  search: checkPermission('externaladmin:finishedProductManage:product:search'),
  batchPutOn: checkPermission('externaladmin:finishedProductManage:product:batchPutOn'),
  batchPutOff: checkPermission('externaladmin:finishedProductManage:product:batchPutOff'),
  batchModifyStyle: checkPermission('externaladmin:finishedProductManage:product:batchModifyStyle'),
  sign: checkPermission('externaladmin:finishedProductManage:product:sign'),
  batchSign: checkPermission('externaladmin:finishedProductManage:product:batchSign'),
  putOn: checkPermission('externaladmin:finishedProductManage:product:putOn'),
  putOff: checkPermission('externaladmin:finishedProductManage:product:putOff'),
  generateKnifeImage: checkPermission('externaladmin:finishedProductManage:product:generateKnifeImage'),
  generateShowImage: checkPermission('externaladmin:finishedProductManage:product:generateShowImage'),
  delete: checkPermission('externaladmin:finishedProductManage:product:delete'),
  linkToDetail: checkPermission('externaladmin:finishedProductManage:product:linkToDetail')
}

export default {
  components: {
    putOnOrPutOff,
    batchModifyStyle,
    selectMerchant,
    myHeader,
    checkAllData,
    defaultImg,
    detailDialog,
    batchSign
  },
  mixins: [initDataMixin],
  data() {
    return {
      permission,
      IS_PUBLIC_ON_SHELF,
      cols,
      url: 'externaladmin/productService/finishedProd/productList',
      checkAll: false,
      styleVisible: false,
      allMerchantList: [],
      allStyleList: [],
      selectionData: [],
      visible: false,
      dialogVisible: false,
      selectionList: [],
      selectionDataAllArr: [],
      sup_this: this,
      protoCount: 0,
      selectCount: 0,
      type: 'putOn',
      query: {
        isPublicOnShelf: ''
      },
      detailData: {}
    }
  },
  computed: {
    showStatus() {
      return ({ customProductList }, state) => {
        return customProductList?.some(({ customSpecificProductList }) => {
          return customSpecificProductList?.some(({ isPublicOnShelf }) => isPublicOnShelf == state)
        })
      }
    },

    knifeImageList() {
      try {
        return ({ customProductList, $sizeIndex }) => {
          return flat(
            $GET(customProductList, `${$sizeIndex}.customSpecificProductList`, []).map(({ knifeLayoutList }) => {
              return knifeLayoutList?.map(({ knifeLayoutImagePath }) => knifeLayoutImagePath)
            })
          )
        }
      } catch (error) {}
    },
    showImageList() {
      try {
        return ({ customProductList }) => {
          return flat(
            customProductList?.map(({ customShowImageList }) => {
              return customShowImageList?.map(({ clearPath }) => clearPath)
            })
          )
        }
      } catch (error) {}
    },
    getSize() {
      return (row) => {
        return flat(
          $GET(row, `customProductList.${row.$sizeIndex}.customSpecificProductList`, []).map(({ sizeName }) => sizeName)
        )?.filter(Boolean)
      }
    },
    getSku() {
      return (type, row) => {
        return $GET(row, `customProductList.${row.$sizeIndex}.customSpecificProductList`, []).map((item) => item[type])
      }
    },
    curPath() {
      return (item) => {
        return item + '?x-oss-process=image/resize,w_500,limit_0'
      }
    },

    formatName() {
      try {
        return ({ customProductList, $sizeIndex }) => {
          return flat(
            $GET(customProductList, `${$sizeIndex}.customSpecificProductList`, []).map(({ knifeLayoutList }) => {
              return knifeLayoutList?.map(({ knifeLayoutImagePath }) => {
                const path = knifeLayoutImagePath || ''
                return path.split('/').slice(-1)
              })
            })
          )
        }
      } catch (error) {}
    }
  },
  methods: {
    rewriteMap,
    parseTime,
    async getCount() {
      const data = await this.$refs.checkAllData.getSelectionDataAllArr()
      this.selectCount = this.checkAll ? data.length : this.protoCount.length
    },
    getPicTitle(data) {
      return data.imageList.map(({ thumbnailPath }) => getFileName(thumbnailPath))
    },
    getTitle(data) {
      return data.imageList?.[0]?.title
    },
    getTortStatus(data) {
      const { isTort } = data
      switch (isTort) {
        case 0:
          return '未侵权'
        case 1:
          return '侵权'
      }
    },
    showDialog(data) {
      this.type = 'single'
      this.selectionData = [data]
      this.dialogVisible = true
    },
    cellClassName({ columnIndex }) {
      if ([4, 5, 8, 9, 10].includes(columnIndex)) {
        return 'clearTablePadding'
      }
    },
    handleStyleClicked(index, curIndex) {
      //响应式更新数据的另一种方法
      const item = this.data[curIndex]
      item.$sizeIndex = index
      this.data.splice(curIndex, 1, item)
    },
    getStyle({ collectionTagList }) {
      return collectionTagList?.map(({ description }) => description)
    },
    getStyleName({ customProductList }) {
      return customProductList?.map((item) => item)
    },
    async getMerchantList() {
      try {
        const page = { pageIndex: 1, pageSize: 0 }
        const { code, detail } = await getMerchantList({ page })
        if (code != 0) return
        this.allMerchantList = detail
      } catch (error) {}
    },
    async getAllData() {
      let res = await awaitResolve(
        getProductList({
          page: {
            pageIndex: 1,
            pageSize: 0
          },
          ...this.query
        })
      )
      res = res?.detail
      if (!res) this.$message.warning('获取数据失败，请重试')
      return res
    },
    getElTableInstance() {
      return getValueFromObj(this.$refs, 'tableRef.table')
    },
    // 全选必加
    initCallBack(res, postData) {
      let initCallBack = getValueFromObj(this.$refs.checkAllData, 'initCallBack')
      if (initCallBack) initCallBack(res?.detail || [], postData.page.pageIndex)
      this.data?.map((item) => {
        item.$sizeIndex = 0
        this.$set(item, '$isExpand', false)
        this.$set(item, '$sizeList', this.getSize(item) || [])
      })
    },
    async checkAllStatusChange(status) {
      this.protoCount = this.selectedData.map(({ productPrototype }) => productPrototype).length
      this.checkAll = status
      if (['indeterminate', 'all'].includes(status)) {
        this.selectionDataAllArr = [true]
      } else {
        this.selectionDataAllArr = []
      }
    },
    search() {
      if (typeof this.query.createTime == 'object' || typeof this.query.putTime == 'object') {
        if (this.query.createTime) {
          this.query.startCreateTime = this.query.createTime[0] + ' 00:00:00'
          this.query.endCreateTime = this.query.createTime[1] + ' 23:59:59'
          // delete this.query.createTime
        } else {
          this.query.startCreateTime = ''
          this.query.endCreateTime = ''
        }
        if (this.query.putTime) {
          this.query.startShelfTime = this.query.putTime[0] + ' 00:00:00'
          this.query.endShelfTime = this.query.putTime[1] + ' 23:59:59'
          // delete this.query.createTime
        } else {
          this.query.startShelfTime = ''
          this.query.endShelfTime = ''
        }
      }
      this.searchChange()
    },
    async handleSelectionChange(selection) {
      this.selectedData = selection
      this.protoCount = this.selectedData.map(({ productPrototype }) => productPrototype).length

      this.$nextTick(function () {
        this.getCount()
      })
    },
    handleChange() {},
    async changeStatus(data, type) {
      let selectionData = []
      switch (type) {
        case 'putOn':
          selectionData = [data]
          this.type = 'putOn'
          this.visible = true
          break
        case 'putOff':
          selectionData = [data]
          this.type = 'putOff'
          this.visible = true
          break
        case 'batchPutOn':
          selectionData = this.checkAll ? await this.$refs.checkAllData.getSelectionDataAllArr() : this.selectedData
          if (!selectionData.length) {
            this.$message.warning('请先选择数据后再进行批量操作')
            return
          }
          this.type = 'batchPutOn'
          this.visible = true
          break
        case 'batchPutOff':
          selectionData = this.checkAll ? await this.$refs.checkAllData.getSelectionDataAllArr() : this.selectedData
          if (!selectionData.length) {
            this.$message.warning('请先选择数据后再进行批量操作')
            return
          }
          this.type = 'batchPutOff'
          this.visible = true
          break
      }
      const allSelectionData = deepClone(selectionData)
        ?.map((item) => {
          if (!item.customProductList) {
            item.customProductList = []
          }
          let { customProductList } = item
          customProductList?.map((cItem) => {
            if (!cItem.customSpecificProductList) {
              cItem.customSpecificProductList = []
            }
          })
          return item
        })
        ?.filter(({ customProductList }) => {
          return (
            customProductList.length &&
            customProductList.some(({ customSpecificProductList }) => customSpecificProductList.length)
          )
        })

      const state = ['putOn', 'batchPutOn'].includes(this.type) ? 0 : 1
      this.selectionData = deepClone(allSelectionData)?.filter(({ customProductList }) => {
        let isPass = customProductList.some(({ customSpecificProductList }) => {
          const bool = customSpecificProductList.some(({ isPublicOnShelf }) => {
            return isPublicOnShelf === state
          })
          return bool
        })
        this.selectionData?.map((item) => {
          const { customProductList } = item
          customProductList?.map((cItem) => {
            cItem.customSpecificProductList = cItem.customSpecificProductList?.filter(({ isPublicOnShelf }) => {
              return isPublicOnShelf == state
            })
          })
        })
        return isPass
      })
    },
    async generate(row, type) {
      const { customProductList } = row
      const customSpecificProductIdList = flat(
        customProductList?.map(({ customSpecificProductList }) => customSpecificProductList?.map(({ id }) => id))
      )
      if (type == 'knifeImage') {
        this.$set(row, 'knifeLoading', true)
        try {
          const { code } = await testAlgo({ customSpecificProductIdList })
          if (code != 0) return
          row.knifeLoading = false
          this.init()
          this.$message.success('操作成功')
        } catch (error) {
          row.knifeLoading = false
        }
      } else {
        this.$set(row, 'showLoading', true)
        try {
          const idList = customProductList?.map(({ id }) => id)
          idList?.map(async (id) => {
            const { code } = await testAlgoShowImg({ customProductId: id })
            if (code != 0) return
          })
          row.showLoading = false
          this.init()
          this.$message.success('操作成功')
        } catch (error) {
          row.showLoading = false
        }
      }
    },
    linkToDetail(data) {
      this.detailData = data
      this.$refs.detailDialog.dialogVisible = true
    },
    getState({ customProductList, $sizeIndex }) {
      let tempArr = []
      console.log($GET(customProductList, `${$sizeIndex}.customSpecificProductList`, []), $sizeIndex)
      $GET(customProductList, `${$sizeIndex}.customSpecificProductList`, []).map(({ isPublicOnShelf }) => {
        const item = isPublicOnShelf == 0 ? '未上架' : '已上架'
        tempArr.push(item)
      })
      return tempArr
    },
    async batchOperation(type) {
      this.selectionList = this.checkAll ? await this.$refs.checkAllData.getSelectionDataAllArr() : this.selectedData
      if (!this.selectionList.length) {
        this.$message.warning('请先选择数据后再进行批量操作！')
        return
      }
      if (type == 'modify') this.styleVisible = true
      else {
        this.type = 'batch'
        this.selectionData = this.selectionList
        this.dialogVisible = true
      }
    },

    isSizeExpand(row, index) {
      return (index > 0 && row.$isExpand) || index === 0
    }
  },
  async created() {
    this.getMerchantList()
    try {
      const page = {
        pageSize: 0,
        pageIndex: 1
      }
      const { code: styleCode, detail: styleDetail } = await getStyleList({ page })
      if (styleCode != 0) return
      this.allStyleList = styleDetail.filter(({ isEnabled }) => isEnabled == 1)
    } catch (error) {}
  }
}
</script>

<style lang="scss">
.clearTablePadding.clearTablePadding.clearTablePadding.clearTablePadding {
  padding: 0;
}
</style>

<style lang="scss" scoped>
.m150 {
  max-width: 180px;
}
.cp {
  cursor: pointer;
}
.active {
  border: 1px solid #384edb;
}
.pagination_wrapper {
  position: fixed;
  bottom: 15px;
  right: 45px;
  height: 100px;
  left: 244px;
  z-index: 10;
  border-top: 1px solid #ebeef5;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.row-line {
  margin: 0 -10px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  & + .row-line {
    border-top: 1px solid #ebeef5;
  }
}
.app-container {
  font-size: 14px;
  padding: 20px 30px 100px 30px;
  ::v-deep {
    .el-table__fixed {
      bottom: 0 !important;
    }
    .el-table__fixed-body-wrapper .el-table__body {
      padding-bottom: 10px; // 滚动条高度
    }
    .cell {
      .el-divider.hidden {
        visibility: hidden;
      }
      .el-divider__text {
        padding: 0 5px;
      }
    }
  }
}
</style>
