<template>
  <div>
    <BaseDialog
      class="dialog-warpper mt-40"
      :dialogVisible="styleVisible"
      title="批量修改风格"
      width="40%"
      @closeHandle="handleClose"
    >
      <template>
        <el-pagination
          class="defaultClass"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageIndex"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="selectionListCopy.length"
        >
        </el-pagination>
        <el-table height="390px" :data="finalData" style="width: 100%">
          <el-table-column prop="name" label="产品名称"> </el-table-column>
          <el-table-column label="风格">
            <template slot-scope="{ row }">
              <el-tag class="mr10 mt10" type="primary" v-for="(item, index) in getStyle(row)" :key="index">
                {{ item }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ $index }">
              <el-button @click="onRemove($index)" type="text" size="small">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="defaultClass"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageIndex"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="selectionListCopy.length"
        >
        </el-pagination>
        <div class="mt20 select-list">
          <div class="w70">风格选择:</div>
          <div>
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll">全部</el-checkbox>
            <el-checkbox-group v-model="selectedStyleList" style="display: inline">
              <el-checkbox v-for="item in allStyleList" :key="item.id" :label="item.id">{{
                item.description
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </template>
      <template #footer>
        <loadingBtn :isDisabled="!!!selectionListCopy.length" type="primary" @click="onConfirm"> 确定 </loadingBtn>
        <loadingBtn @click="onCancel"> 取消 </loadingBtn>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { deepClone } from '@/components/avue/utils/util'
import { batchRelationStyle } from '@/api/finishedProductManageApi'

export default {
  props: {
    styleVisible: {
      type: Boolean,
      default: false
    },
    selectionList: {
      type: Array,
      default: () => []
    },
    allStyleList: {
      type: Array,
      default: () => []
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedStyleList: [],
      selectedStyleListCopy: [],
      selectionListCopy: [],
      page: {
        pageIndex: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    isIndeterminate() {
      return this.selectedStyleList.length > 0 && this.selectedStyleList.length < this.allStyleList.length
    },
    finalData() {
      const start = (this.page.pageIndex - 1) * this.page.pageSize
      return this.selectionListCopy.slice(start, start + this.page.pageSize)
    },
    checkAll: {
      get() {
        return this.selectedStyleList.length == this.allStyleList.length
      },
      set(bool) {
        if (bool) {
          this.selectedStyleList = this.allStyleList.map(({ id }) => id)
        }
        if (!bool) {
          this.selectedStyleList = []
        }
      }
    }
  },
  created() {
    this.selectedStyleListCopy = deepClone(this.selectedStyleList)
    this.selectionListCopy = deepClone(this.selectionList)
  },
  methods: {
    handleClose() {
      this.$emit('update:styleVisible', false)
    },
    handleSizeChange(val) {
      this.page.pageSize = val
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val
    },
    getStyle({ collectionTagList }) {
      return collectionTagList?.map(({ description }) => description)
    },
    onCancel() {
      this.selectedStyleList = this.selectedStyleListCopy
      this.$emit('update:styleVisible', false)
    },
    async onConfirm() {
      const collectionIdList = this.selectionListCopy.map(({ id }) => id)
      try {
        const data = {
          collectionIdList,
          tagIdList: this.selectedStyleList
        }
        const { code } = await batchRelationStyle(data)
        if (code != 0) return
        this.$emit('update:styleVisible', false)
        this.sup_this.init()
        this.$message.success('批量修改成功')
      } catch (error) {}
    },
    onRemove(index) {
      this.selectionListCopy.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-40 {
  margin-top: -40px;
}
.dialog-warpper {
  .defaultClass.el-pagination {
    text-align: right;
    padding: 10px 0;
    padding-right: 15px;
  }
  .select-list {
    max-height: 200px;
    overflow-y: auto;
    display: flex;
  }
  .w70 {
    width: 70px;
    line-height: 37px;
  }
  ::v-deep {
    .el-table__fixed {
      bottom: 0 !important;
    }
    .el-table__fixed-body-wrapper .el-table__body {
      padding-bottom: 10px; // 滚动条高度
    }
    .el-checkbox {
      margin: 10px 10px 0 0;
    }
  }
}
</style>
