<template>
  <div>
    <BaseDialog
      v-if="dialogVisible"
      title="详情"
      top="8vh"
      width="50%"
      :dialogVisible.sync="dialogVisible"
      :hiddenFooter="true"
    >
      <div class="content-warpper">
        <div class="basic-information-warpper">基础信息</div>
        <div class="flex">
          <span class="count">定制产品名称：{{ data.chineseName }}</span>
          <span class="count">定制产品英文名称：{{ data.englishName }}</span>
        </div>
        <div class="flex">
          <span class="count">款式：{{ handleStyle(data) }}</span>
          <span class="count">尺码：{{ handleSize(data) }}</span>
        </div>
        <div class="flex">
          <span class="count">账号：{{ data.createByAccount }}</span>
          <span class="count"
            >分销商：
            <span v-if="data.mainUser"> {{ data.mainUser }} -</span>
            {{ data.createByName }}
          </span>
        </div>
        <div class="flex">
          <span class="count">定制时间：{{ data.createTime | formatTime }}</span>
          <span class="count">上架时间：{{ data.publicShelfTime | formatTime }}</span>
        </div>
        <div class="information-warpper">原型信息</div>
        <div class="flex-warpper">
          <div class="left">
            <div class="model">原型：{{ data.name }}</div>
            <defaultImg :src="picture" width="100" height="100" :content="data.chineseName"></defaultImg>
          </div>
          <div class="right">
            <div class="model">原图：</div>
            <defaultImg
              width="100"
              height="100"
              :carouselList="imgList"
              :content="getFileName(imgList[0])"
            ></defaultImg>
          </div>
        </div>
        <div class="picture-warpper">
          <div>效果图:</div>
          <el-button
            type="primary"
            style="padding: 0; height: 30px; width: 100px"
            icon="el-icon-download"
            @click="downloadDesignedPicture"
            >批量下载</el-button
          >
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in handleTab" :key="index" :label="item" :name="index.toString()">
          </el-tab-pane>
          <span v-for="item in currentTab" :key="item">
            <defaultImg :src="item" width="100" height="100" :content="data.chineseName"></defaultImg>
          </span>
        </el-tabs>
        <div class="basic-picture-warpper">
          <div>刀版图:</div>
          <el-button
            :loading="loading"
            type="primary"
            style="padding: 0; height: 30px; width: 100px"
            icon="el-icon-download"
            @click="downloadBasicPicture"
            >批量下载</el-button
          >
        </div>
        <div class="table-warpper">
          <el-table :data="specProds" border ref="watchTableRef">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="自定义sku">
              <template slot-scope="{ row }">
                {{ row.customSku || '暂无' }}
              </template>
            </el-table-column>
            <el-table-column label="外部sku">
              <template slot-scope="{ row }">
                <span @click="showDialog(row)" style="color: #3841db; cursor: pointer">查看外部sku</span>
              </template>
            </el-table-column>
            <el-table-column prop="basicPicture" label="刀版图">
              <template slot-scope="{ row }">
                <defaultImg :carouselList="knifeImageList(row)" :contentList="formatName(row)"></defaultImg>
              </template>
            </el-table-column>
            <el-table-column prop="isBasicGenerate" label="刀版图是否生成">
              <template slot-scope="{ row }">
                <span v-if="knifeImageList(row).length">已生成</span>
                <span v-else>未生成</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  :loading="row.loading"
                  v-if="knifeImageList(row).length"
                  style="color: #3841db; cursor: pointer"
                  @click="downloadImage(row, knifeImageList(row))"
                  >下载</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </BaseDialog>
    <sku-dialog v-if="visible" :skuVisible.sync="visible" :productId="productId" />
  </div>
</template>

<script>
import { basicListCols as cols } from '@/views/picture/basePicListPage/module/designedDialog/cols'
import { createRandomNum, getFileName } from '@/utils'
import { downloadByZip, downloadFile } from '@/utils/download'
import defaultImg from '@/views/components/defaultImg'
import skuDialog from './skuDialog.vue'

export default {
  components: {
    skuDialog,
    defaultImg
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      productId: 0,
      loading: false,
      visible: false,
      dialogVisible: false,
      cols,
      activeName: '0',
      currentImageIndex: 0,
      tableData: [
        {
          orderNumber: '',
          way: '',
          expressNumber: '',
          expressTime: '',
          basicState: '',
          importTime: '',
          state: ''
        }
      ]
    }
  },
  computed: {
    specProds() {
      try {
        const { customSpecificProductList } = this.data.customProductList[this.activeName]
        if (!Array.isArray(customSpecificProductList)) {
          return []
        }
        return customSpecificProductList
      } catch (err) {
        return []
      }
    },
    currentImage() {
      try {
        if (!this.originalPicture) return
        return this.originalPicture[this.currentImageIndex].path
      } catch (err) {}
    },
    currentTab() {
      try {
        if (!this.data) return
        return (
          this.data &&
          this.data.customProductList[this.activeName].customShowImageList.map((item) => {
            return item.showImagePath
          })
        )
      } catch (error) {}
    },
    picture() {
      try {
        if (!this.data) return
        return this.data && this.data.customProductList[0].style.styleDisplayImageList[0].displayImagePath
      } catch (error) {}
    },
    originalPicture() {
      try {
        if (!this.data) return
        let picList = []
        this.data.imageList.map((item) => {
          picList.push(item.thumbnailPath)
        })
        picList = picList.map((item, index) => ({
          path: item,
          currentImageIndex: index
        }))
        return picList
      } catch (error) {}
    },
    imgList({ originalPicture }) {
      return originalPicture.map(({ path }) => path)
    },
    handleTab() {
      const data = this.data
      return (
        data.customProductList &&
        data.customProductList.map((item) => {
          return item.styleName
        })
      )
    },
    getExternalSkuList() {
      return (row) => {
        try {
          const data = {
            specificProductId: row.id
          }
          let arr = []
          getSkuList(data).then(({ detail }) => {
            console.log(detail)
            arr = detail.map(({ externalSku, ownerName }) => {
              console.log(externalSku, ownerName)
              return externalSku + ownerName
            })
          })

          return arr
        } catch (error) {}
      }
    }
  },
  methods: {
    getFileName,
    showDialog(row) {
      this.productId = row.id
      this.visible = true
    },
    slideChangeHandler(activeIndex) {
      const item = this.originalPicture[activeIndex]
      this.currentImageIndex = activeIndex
      item.currentImageIndex = activeIndex
    },
    async downloadImage(row, data) {
      this.$set(row, 'loading', true)
      if (data.length == 1) {
        await downloadFile(data[0])
        return (row.loading = false)
      }
      const paths = data.map((url) => ({ url }))
      await downloadByZip(paths)
      row.loading = false
    },
    knifeImageList(row) {
      const paths = []
      const list = row?.knifeLayoutList || []
      list.map(({ knifeLayoutImagePath }) => paths.push(knifeLayoutImagePath))
      console.log('paths', paths)
      return paths.filter(Boolean)
    },
    //使用迅雷下载
    downloadByPath(paths) {
      const tasks = paths.map((url) => {
        return {
          name: '',
          url: `${url}?uid=${createRandomNum()}`
        }
      })
      thunderLink.newTask({
        downloadDir: '', // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
        tasks
      })
    },
    handleStyle(data) {
      const style =
        data.customProductList &&
        data.customProductList.map((item) => {
          return item.styleName
        })
      return style && style.join(' ')
    },
    formatName(data) {
      return data?.knifeLayoutList?.map(({ knifeLayoutImagePath }) => {
        const path = knifeLayoutImagePath || ''
        return path.split('/').slice(-1)[0]
      })
    },
    handleSize(data) {
      const size =
        data.customProductList &&
        data.customProductList[0].customSpecificProductList.map((item) => {
          return item.sizeName
        })
      return size && size.join(' ')
    },
    handleStyle(data) {
      const style =
        data.customProductList &&
        data.customProductList.map((item) => {
          return item.styleName
        })
      return style && style.join(' ')
    },
    handleClick(tab) {},
    download(data) {
      let paths = []
      const url = data.path
      if (url) {
        paths.push(url)
      }
      this.downloadByPath(paths) // 修改文件夹名字，填在空字符串内
      return
    },
    downloadDesignedPicture() {
      let paths = []
      this.data?.customProductList?.map((product) => {
        product?.customShowImageList?.map((figure) => {
          let path = figure?.clearPath || figure?.showImagePath
          if (path) {
            paths.push(path)
          }
        })
      })
      if (!paths.length) return this.$message.warning('没有可下载的效果图，下载失败')
      return this.downloadByPath(paths)
    },
    async downloadBasicPicture() {
      this.loading = true
      let selections = this.$refs.watchTableRef.selection
      if (!selections.length) {
        this.$message.warning('请先选择刀版图后再进行批量下载操作')
        return (this.loading = false)
      }
      let paths = []
      selections.map((selection) => {
        selection.knifeLayoutList.map(({ knifeLayoutImagePath }) => {
          if (knifeLayoutImagePath) {
            paths.push(knifeLayoutImagePath)
          }
        })
      })
      if (!paths.filter(Boolean).length) {
        this.loading = false
        return this.$message.warning('无可下载的刀版图')
      }
      await this.downloadByPath(paths)
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-warpper {
  height: 700px;
  overflow: auto;
  padding: 22px 0 18px 24px;
  .basic-picture-warpper {
    margin: 40px 24px 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep {
    .el-tabs__nav-wrap {
      margin-right: 24px !important;
    }
  }
  .information-warpper {
    padding-top: 24px;
  }
  .picture-warpper {
    margin: 34px 24px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-warpper {
    display: flex;
    ::v-deep {
      .el-popover__reference-wrapper {
        display: inline-block;
        width: 100px;
      }
    }
    .left,
    .right {
      flex: 1;
      .model {
        margin: 18px 0 20px 0;
      }
    }
  }
  .basic-information-warpper {
    margin-bottom: 23px;
  }
  .flex {
    display: flex;
    margin-bottom: 14px;
    .count {
      flex: 1;
    }
  }
}
</style>
